<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="size+'px'" viewBox="0 0 46.579 100">
        <path :fill="color" id="fill" d="M-967.982,494.894a1.316,1.316,0,0,1-1.316,1.316h-10a4.437,4.437,0,0,0-4.473,4.4v10.6h14.212a1.317,1.317,0,0,1,1.311,1.317l-1.311,14.736a1.3,1.3,0,0,1-1.317,1.185H-983.9v49.079a1.323,1.323,0,0,1-1.317,1.317h-17.632a1.321,1.321,0,0,1-1.316-1.317V528.447h-8.946a1.323,1.323,0,0,1-1.317-1.317V512.394a1.317,1.317,0,0,1,1.317-1.316h8.946V496.866a17.975,17.975,0,0,1,17.928-18.023h17.072a1.315,1.315,0,0,1,1.316,1.311v14.741Z" transform="translate(1014.429 -478.843)"/>
    </svg>
</template>
<script>
    export default {
        name: 'iconFacebook',
        props: {
            size: {
                type: Number,
                default: 20,
            },
            color: {
                type: String,
                default: 'var(--main-navy)'
            }
        }
    }
</script>